import React, { useEffect, useState } from "react";
import Panels from "./Components/Panels";
import Inversores from "./Components/Inversores";
import Estructura from "./Components/Estructura";
import Dongle from "./Components/Dongle";
import Ingenieria from "./Components/Ingenieria";
import Instalacion from "./Components/Instalacion";
import KitProteccionesCableado from "./Components/KitProteccionesCableado";
import TasaObras from "./Components/TasaObras";
import Meter from "./Components/Meter";
import { Container, Box, Button } from "@mui/material";
import Drawer from "./Components/Drawer";
import CustomItem from "./Components/CustomItem";
import Battery from "./Components/Battery";
import KitCompleto from "./Components/KitCompleto";
import AerotermiaCalculadorPotencia from "./Components/AerotermiaCalculadorPotencia";
import Aerotermias from "./Components/Aerotermias";
import ChildItem from "./Components/ChildItem";
import Cargadores from "./Components/Cargadores";
import Acumuladores from "./Components/Acumuladores";

const App = () => {
  const [strings, setStrings] = useState([]);
  const [stringPower, setStringPower] = useState(0);
  const [totalPower, setTotalPower] = useState(0);
  const [presupuesto, setPresupuesto] = useState([]);
  const [kitSelected, setKitSelected] = useState({ ud: 0 });
  const [panelSelected, setPanelSelected] = useState({ ud: 0 });
  const [inversorSelected, setInversorSelected] = useState({});
  const [estructuraSelected, setEstructuraSelected] = useState({
    id: 1,
    tipoCubierta: "inclinada",
    name: "Kit estructura de fijación de paneles solares coplanar",
    importe: 0,
  });
  const [donglePrice, setDonglePrice] = useState({ ud: 1, importe: 0 });
  const [ingenieriaPrice, setIngenieriaPrice] = useState({
    name: "Ingeniería, legalización conforme normativa vigente. Certificado de instalación eléctrica",
    ud: 1,
    importe: 0,
    total: 0,
  });
  const [instalacionPrice, setInstalacionPrice] = useState({
    name: "Instalación y puesta en marcha del sistema fotovoltaico",
    ud: 1,
    importe: 0,
    total: 0,
  });
  const [kitProteccionesCableadoPrice, setKitProteccionesCableadoPrice] =
    useState({
      name: "Kit de protecciones, cableado y materiales pequeños",
      ud: 1,
      importe: 0,
      total: 0,
    });
  const [tasaObrasPrice, setTasaObrasPrice] = useState({
    name: "Visita técnica y tramitación de permiso de obra",
    ud: 1,
    importe: 0,
    total: 0,
  });
  const [meterPrice, setMeterPrice] = useState({
    name: "Medidor inteligente Huawei",
    ud: 1,
    importe: 0,
    total: 0,
  });
  const [totalSinIva, setTotalSinIva] = useState(0);
  const [customItems, setCustomItems] = useState([]);
  const [batteryPrice, setBatteryPrice] = useState({
    name: "BMS Huawei con un módulo de batería 5kWh",
    ud: 1,
    importe: 0,
    total: 0,
  });
  const [customItemsAerotermia, setCustomItemsAerotermia] = useState([]);
  const [aerotermiaSelected, setAerotermiaSelected] = useState({});
  const [inercia, setInercia] = useState({
    name: "Acumulador Inercia Greenheiss",
    ud: 1,
    importe: 504,
    total: 504,
  });
  const [aerotermiaMaterial, setAerotermiaMaterial] = useState({
    name: "Pequeño material hidráulico: Válvulas seguridad, válvulas esfera, machón, bomba, desconector hidráulico, coquillas, filtros de agua, vasos de expansión, etc",
    ud: 1,
    importe: 1829.75,
    total: 1829.75,
  });
  const [instalacionAerotermia, setInstalacionAerotermia] = useState({
    name: "Instalación y puesta en marcha",
    ud: 1,
    importe: 2625,
    total: 2625,
  });
  const [cargadorSelected, setCargadorSelected] = useState({});
  const [instalacionCargador, setInstalacionCargador] = useState({
    name: "Instalación de 15m de distancia y puesta en marcha",
    ud: 1,
    importe: 290,
    total: 290,
  });
  const [cajaProteccion, setCajaProteccion] = useState({
    name: "Caja de protecciones 32A",
    ud: 1,
    importe: 255,
    total: 255,
  });
  const [magnetoTermico, setMagnetoTermico] = useState({
    name: "Magnetotérmico 2P 32A",
    ud: 1,
    importe: 35,
    total: 35,
  });
  const [certificado, setCertificado] = useState({
    name: "Certificado eléctrico de la instalación",
    ud: 1,
    importe: 160,
    total: 160,
  });
  const [extraMetros, setExtraMetros] = useState({
    name: "Extra metros 3x10 mm2 en tubo PVC",
    ud: 0,
    importe: 20,
    total: 0,
  });
  const [taladro, setTaladro] = useState({
    name: "Taladro en forjado",
    ud: 0,
    importe: 60,
    total: 0,
  });
  const [nnss, setNnss] = useState({
    name: "Nuevo suministro",
    ud: 0,
    importe: 200,
    total: 0,
  });
  const [customItemsCargador, setCustomItemsCargador] = useState([]);
  const [acumuladorSelected, setAcumuladorSelected] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState(null);

  const handleLogin = () => {
    const enteredUsername = prompt("Enter your username:");
    const enteredPassword = prompt("Enter your password:");

    // Replace this with your actual authentication logic
    if (enteredUsername === "admin" && enteredPassword === "password") {
      setIsLoggedIn(true);
      setUsername(enteredUsername);
    } else {
      alert("Invalid credentials");
    }
  };

  useEffect(() => {}, []);

  const addKit = (event) => {
    event.preventDefault();

    const lines = [];

    if (kitSelected.total !== 0) {
      lines.push(kitSelected);
    }

    setStrings([
      ...strings,
      {
        power: stringPower,
        panelTotal: Number(panelSelected.ud),
        lines: lines,
      },
    ]);
    setTotalPower(totalPower + stringPower);
    setStringPower(0);
    setKitSelected({ ...kitSelected, total: 0 });
    setTotalSinIva(totalSinIva + kitSelected.total);
  };

  const addString = (event) => {
    event.preventDefault();

    const lines = [];

    if (panelSelected.total !== 0) {
      lines.push(panelSelected);
    }

    if (inversorSelected.total !== 0) {
      lines.push(inversorSelected);
    }

    if (estructuraSelected.total !== 0) {
      lines.push(estructuraSelected);
    }

    setStrings([
      ...strings,
      {
        power: stringPower,
        panelTotal: Number(panelSelected.ud),
        lines: lines,
      },
    ]);
    setTotalPower(totalPower + stringPower);
    setStringPower(0);
    setPanelSelected({ ...panelSelected, ud: 0, total: 0 });
    setTotalSinIva(
      totalSinIva +
        panelSelected.total +
        inversorSelected.total +
        estructuraSelected.total
    );
  };

  const addProduct = (event) => {
    event.preventDefault();

    const lines = [];

    if (meterPrice.total !== 0) {
      lines.push(meterPrice);
    }

    if (donglePrice.total !== 0) {
      lines.push(donglePrice);
    }

    if (batteryPrice.total !== 0) {
      lines.push(batteryPrice);
    }

    if (ingenieriaPrice.total !== 0) {
      lines.push(ingenieriaPrice);
    }

    if (instalacionPrice.total !== 0) {
      lines.push(instalacionPrice);
    }

    if (kitProteccionesCableadoPrice.total !== 0) {
      lines.push(kitProteccionesCableadoPrice);
    }

    if (tasaObrasPrice.total !== 0) {
      lines.push(tasaObrasPrice);
    }

    customItems.forEach((customItem) => {
      if (customItem.total !== 0) {
        lines.push(customItem);
      }
    });

    setPresupuesto([...presupuesto, { lines: lines }]);

    const totalPresupuesto = lines.reduce((acc, line) => {
      return acc + line.total;
    }, 0);

    setTotalSinIva(totalSinIva + totalPresupuesto);
  };

  const addProductAerotermia = (event) => {
    event.preventDefault();

    const lines = [];

    if (aerotermiaSelected.total !== 0) {
      lines.push(aerotermiaSelected);
    }

    customItemsAerotermia.forEach((customItem) => {
      if (customItem.total !== 0) {
        lines.push(customItem);
      }
    });

    if (inercia.total !== 0) {
      lines.push(inercia);
    }

    if (acumuladorSelected.total !== 0) {
      lines.push(acumuladorSelected);
    }

    if (aerotermiaMaterial.total !== 0) {
      lines.push(aerotermiaMaterial);
    }

    if (instalacionAerotermia.total !== 0) {
      lines.push(instalacionAerotermia);
    }

    setPresupuesto([...presupuesto, { lines: lines }]);
    const totalPresupuesto = lines.reduce((acc, line) => {
      return acc + line.total;
    }, 0);

    setTotalSinIva(totalSinIva + totalPresupuesto);
  };

  const addProductCargador = (event) => {
    event.preventDefault();

    const lines = [];

    if (cargadorSelected.total !== 0) {
      lines.push(cargadorSelected);
    }

    customItemsCargador.forEach((customItem) => {
      if (customItem.total !== 0) {
        lines.push(customItem);
      }
    });

    if (instalacionCargador.total !== 0) {
      lines.push(instalacionCargador);
    }

    if (cajaProteccion.total !== 0) {
      lines.push(cajaProteccion);
    }

    if (magnetoTermico.total !== 0) {
      lines.push(magnetoTermico);
    }

    if (certificado.total !== 0) {
      lines.push(certificado);
    }

    if (extraMetros.importe !== 0) {
      lines.push(extraMetros);
    }

    if (taladro.importe !== 0) {
      lines.push(taladro);
    }

    if (nnss.importe !== 0) {
      lines.push(nnss);
    }

    setPresupuesto([...presupuesto, { lines: lines }]);
    const totalPresupuesto = lines.reduce((acc, line) => {
      return acc + line.total;
    }, 0);

    setTotalSinIva(totalSinIva + totalPresupuesto);
  };

  return (
    <>
      <Container maxWidth="lg">
        <Box>
          <h2>
            Calculadora de presupuesto autoconsumo{" "}
            <button
              className="btn btn-primary"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              Ver Presupuesto
            </button>
          </h2>

          <h4>Potencia ({stringPower} W)</h4>
          <KitCompleto
            kitSelected={kitSelected}
            setKitSelected={setKitSelected}
            stringPower={stringPower}
            setStringPower={setStringPower}
          />

          <Box marginY={5}>
            <button className="btn btn-primary" onClick={addKit}>
              Añadir al presupuesto
            </button>
          </Box>
        </Box>

        <Box>
          <h4>Potencia ({stringPower} W)</h4>
          <Panels
            panelSelected={panelSelected}
            setPanelSelected={setPanelSelected}
            stringPower={stringPower}
            setStringPower={setStringPower}
          />
          <Inversores
            inversorSelected={inversorSelected}
            setInversorSelected={setInversorSelected}
            stringPower={stringPower}
          />
          <Estructura
            udPaneles={panelSelected.ud}
            estructuraSelected={estructuraSelected}
            setEstructuraSelected={setEstructuraSelected}
          />

          <Box marginY={5}>
            <button className="btn btn-primary" onClick={addString}>
              Añadir al presupuesto
            </button>
          </Box>
        </Box>

        <Box>
          <h4>Otros productos</h4>
          <Meter meterPrice={meterPrice} setMeterPrice={setMeterPrice} />
          <Dongle donglePrice={donglePrice} setDonglePrice={setDonglePrice} />
          <Battery
            batteryPrice={batteryPrice}
            setBatteryPrice={setBatteryPrice}
          />
          <Ingenieria
            totalPower={totalPower}
            ingenieriaPrice={ingenieriaPrice}
            setIngenieriaPrice={setIngenieriaPrice}
          />

          <Instalacion
            totalPower={totalPower}
            instalacionPrice={instalacionPrice}
            setInstalacionPrice={setInstalacionPrice}
          />

          <KitProteccionesCableado
            totalPower={totalPower}
            kitProteccionesCableadoPrice={kitProteccionesCableadoPrice}
            setKitProteccionesCableadoPrice={setKitProteccionesCableadoPrice}
          />

          <TasaObras
            tasaObrasPrice={tasaObrasPrice}
            setTasaObrasPrice={setTasaObrasPrice}
          />

          <h4>
            Productos personalizados{" "}
            <Button
              variant="contained"
              onClick={() =>
                setCustomItems([
                  ...customItems,
                  { name: "", ud: 0, importe: 0, total: 0 },
                ])
              }
            >
              Crear nuevo concepto
            </Button>
          </h4>
          {customItems.map((customItem, i) => (
            <CustomItem
              key={i}
              id={i}
              customItem={customItem}
              setCustomItems={setCustomItems}
              customItems={customItems}
            />
          ))}

          <Box marginY={5}>
            <button className="btn btn-primary" onClick={addProduct}>
              Añadir al presupuesto
            </button>
          </Box>
        </Box>

        <Box>
          <AerotermiaCalculadorPotencia />

          <Aerotermias
            aerotermiaSelected={aerotermiaSelected}
            setAerotermiaSelected={setAerotermiaSelected}
          />
          <Acumuladores
            acumuladorSelected={acumuladorSelected}
            setAcumuladorSelected={setAcumuladorSelected}
          />

          <ChildItem customItem={inercia} setCustomItems={setInercia} />
          <ChildItem
            customItem={aerotermiaMaterial}
            setCustomItems={setAerotermiaMaterial}
          />
          <ChildItem
            customItem={instalacionAerotermia}
            setCustomItems={setInstalacionAerotermia}
          />

          <h4>
            Productos personalizados{" "}
            <Button
              variant="contained"
              onClick={() =>
                setCustomItemsAerotermia([
                  ...customItemsAerotermia,
                  { name: "", ud: 0, importe: 0, total: 0 },
                ])
              }
            >
              Crear nuevo concepto
            </Button>
          </h4>
          {customItemsAerotermia.map((customItem, i) => (
            <CustomItem
              key={i}
              id={i}
              customItem={customItem}
              setCustomItems={setCustomItemsAerotermia}
              customItems={customItemsAerotermia}
            />
          ))}

          <Box marginY={5}>
            <button className="btn btn-primary" onClick={addProductAerotermia}>
              Añadir al presupuesto
            </button>
          </Box>
        </Box>

        <Box>
          <h2>Cargadores eléctricos</h2>
          <Cargadores
            cargadorSelected={cargadorSelected}
            setCargadorSelected={setCargadorSelected}
          />
          <ChildItem
            customItem={instalacionCargador}
            setCustomItems={setInstalacionCargador}
          />
          <ChildItem
            customItem={cajaProteccion}
            setCustomItems={setCajaProteccion}
          />
          <ChildItem
            customItem={magnetoTermico}
            setCustomItems={setMagnetoTermico}
          />
          <ChildItem customItem={certificado} setCustomItems={setCertificado} />
          <ChildItem customItem={extraMetros} setCustomItems={setExtraMetros} />
          <ChildItem customItem={taladro} setCustomItems={setTaladro} />
          <ChildItem customItem={nnss} setCustomItems={setNnss} />
          <h4>
            Productos personalizados{" "}
            <Button
              variant="contained"
              onClick={() =>
                setCustomItemsCargador([
                  ...customItemsCargador,
                  { name: "", ud: 0, importe: 0, total: 0 },
                ])
              }
            >
              Crear nuevo concepto
            </Button>
          </h4>
          {customItemsCargador.map((customItem, i) => (
            <CustomItem
              key={i}
              id={i}
              customItem={customItem}
              setCustomItems={setCustomItemsCargador}
              customItems={customItemsCargador}
            />
          ))}

          <Box marginY={5}>
            <button className="btn btn-primary" onClick={addProductCargador}>
              Añadir al presupuesto
            </button>
          </Box>
        </Box>

        <Drawer
          strings={strings}
          presupuesto={presupuesto}
          totalSinIva={totalSinIva}
        />
      </Container>
    </>
  );
};

export default App;
