import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  PDFViewer,
  StyleSheet,
  Svg,
  G,
  Path,
} from "@react-pdf/renderer";
import moment from "moment/moment";

// Create a PDF component
const MyPDF = ({ dynamicData }) => {
  const styles = StyleSheet.create({
    page: { padding: 30, fontSize: 11, lineHeight: 1.5 },
    section: { color: "white", margin: 30 },
    table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      border: 0.5,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      borderColor: "#bfbfbf",
    },
    tableRow: {
      flexDirection: "row",
    },
    tableCol: {
      borderColor: "#bfbfbf",
      width: "15%",
      padding: 5,
      borderWidth: 0.5,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      textAlign: "right",
    },
    tableCell: {
      marginTop: 5,
      fontSize: 10,
    },
    footer: {
      position: "absolute",
      bottom: 0,
      left: 30,
      right: 30,
      height: 100,
      color: "grey",
      fontSize: 8,
      paddingTop: 20,
    },
  });

  const currentDate = moment().format("DD/MM/YYYY");

  const formatNumber = (number) => {
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    }).format(number);
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <View style={{ marginBottom: 20 }}>
            <Svg width={150} height={60} viewBox="0 0 770 290">
              <G fill="#0036BC">
                <Path d="m35.556 252.365v-181.604h35.463v149.98h82.381v31.624z" />
                <Path d="m236.035 252.365v-11.283c-8.006 8.465-19.295 12.718-30.583 12.718-12.233 0-22.119-4.005-28.937-10.831-9.887-9.885-12.459-21.4-12.459-34.816v-78.331h30.571v74.094c0 16.71 10.578 22.357 20.232 22.357 9.635 0 20.458-5.647 20.458-22.357v-74.094h30.578v122.544h-29.86z" />
                <Path d="m431.021 252.365v-74.078c0-16.686-10.59-22.358-20.231-22.358-9.408 0-20.465 5.673-20.465 21.634v74.803h-30.578v-74.078c0-16.686-10.584-22.358-20.238-22.358-9.634 0-20.458 5.673-20.458 22.358v74.078h-30.578v-122.545h29.874v11.296c7.993-8.245 19.289-12.703 30.578-12.703 13.635 0 24.704 4.938 32.471 15.534 10.345-10.829 21.401-15.534 36.677-15.534 12.239 0 23.301 3.993 30.112 10.804 9.894 9.9 13.415 21.416 13.415 34.829v78.318z" />
                <Path d="m482.291 108.885v-24.929h31.287v24.929zm.465 143.48v-122.544h30.584v122.544z" />
                <Path d="m575.91 253.8c-19.295 0-36.703-2.132-52.225-17.653l19.993-20.006c10.118 10.132 23.288 11.526 32.703 11.526 10.585 0 21.628-3.514 21.628-12.69 0-6.112-3.295-10.339-12.937-11.281l-19.282-1.889c-22.112-2.119-35.76-11.759-35.76-34.337 0-25.396 22.357-39.056 47.287-39.056 19.063 0 35.043 3.296 46.811 14.345l-18.83 19.037c-7.044-6.347-17.867-8.208-28.445-8.208-12.232 0-17.408 5.637-17.408 11.762 0 4.472 1.874 9.629 12.704 10.586l19.269 1.859c24.238 2.365 36.472 15.301 36.472 35.992 0 27.063-23.043 40.013-51.98 40.013z" />
                <Path d="m704.566 252.365v-10.584c-8.233 8.245-15.98 11.761-30.106 11.761-13.88 0-23.993-3.516-31.281-10.806-6.585-6.824-10.12-16.709-10.12-27.54 0-19.515 13.415-35.514 41.873-35.514h28.93v-6.113c0-13.402-6.571-19.282-22.81-19.282-11.761 0-17.169 2.829-23.522 10.133l-19.527-19.063c11.993-13.17 23.754-16.943 44.219-16.943 34.345 0 52.225 14.59 52.225 43.281v80.67zm-.705-51.746h-24.213c-11.068 0-17.188 5.183-17.188 13.88 0 8.465 5.654 14.125 17.654 14.125 8.471 0 13.866-.723 19.275-5.893 3.301-3.05 4.471-8.013 4.471-15.508v-6.604z" />
              </G>
            </Svg>
          </View>

          <Text style={{ fontSize: 14, marginBottom: 20, margenTop: 20 }}>
            Presupuesto #{new Date().valueOf()}
          </Text>
          <Text style={{ fontSize: 10, marginBottom: 10 }}>
            Cliente: {dynamicData.cliente}
          </Text>
          <Text style={{ fontSize: 10, marginBottom: 10 }}>
            Dirección: {dynamicData.direccion}
          </Text>
          <Text style={{ fontSize: 10, marginBottom: 10 }}>
            Fecha: {currentDate}
          </Text>

          <View style={styles.table}>
            <View style={{ ...styles.tableRow, backgroundColor: "#fafafa" }}>
              <View
                style={{ ...styles.tableCol, width: "55%", textAlign: "left" }}
              >
                <Text style={styles.tableCell}>Concepto</Text>
              </View>
              <View style={{ ...styles.tableCol, width: "10%" }}>
                <Text style={styles.tableCell}>Unidad</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Precio</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={{ ...styles.tableCell, textAlign: "right" }}>
                  Total
                </Text>
              </View>
            </View>

            {dynamicData.strings.map((string, i) => (
              <>
                {string.lines.map((line, j) => (
                  <View style={styles.tableRow} key={j}>
                    <View
                      style={{
                        ...styles.tableCol,
                        width: "55%",
                        textAlign: "left",
                      }}
                    >
                      <Text style={styles.tableCell}>{line.name}</Text>
                    </View>
                    <View style={{ ...styles.tableCol, width: "10%" }}>
                      <Text style={styles.tableCell}>{line.ud}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {formatNumber(line.importe)}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {formatNumber(line.total)}
                      </Text>
                    </View>
                  </View>
                ))}
              </>
            ))}

            {dynamicData.presupuesto.map((line, i) => (
              <>
                {line.lines.map((line, j) => (
                  <View style={styles.tableRow} key={j}>
                    <View
                      style={{
                        ...styles.tableCol,
                        width: "55%",
                        textAlign: "left",
                      }}
                    >
                      <Text style={styles.tableCell}>{line.name}</Text>
                    </View>
                    <View style={{ ...styles.tableCol, width: "10%" }}>
                      <Text style={styles.tableCell}>{line.ud}</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {formatNumber(line.importe)}
                      </Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {formatNumber(line.total)}
                      </Text>
                    </View>
                  </View>
                ))}
              </>
            ))}

            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol, width: "55%" }}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={{ ...styles.tableCol, width: "10%" }}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Subtotal</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {formatNumber(dynamicData.totalSinIva)}
                </Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol, width: "55%" }}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={{ ...styles.tableCol, width: "10%" }}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{dynamicData.tipoIva}% IVA</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {formatNumber(
                    (dynamicData.totalSinIva * dynamicData.tipoIva) / 100
                  )}
                </Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol, width: "55%" }}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={{ ...styles.tableCol, width: "10%" }}>
                <Text style={styles.tableCell}></Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Total</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {formatNumber(
                    dynamicData.totalSinIva * (1 + dynamicData.tipoIva / 100)
                  )}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View fixed style={styles.footer}>
          <Text>
            No incluye trabajos de pintura, albañería, carpintería o escayola
            derivados de la ejecución de la instalación. Este presupuesto sería
            válido durante 30 días desde la fecha de emisión.
          </Text>
          <Text>
            COMPAÑÍA LUMISA ENERGÍAS SL - B65711855 - Carrer Ausias Marc, 67 -
            08010 Barcelona - https://lumisa.es - 900 811 473
          </Text>
        </View>
      </Page>
    </Document>
  );
};

const PdfViewer = ({
  strings,
  presupuesto,
  totalSinIva,
  cliente,
  direccion,
  tipoIva,
}) => {
  const dynamicProps = {
    title: "My PDF Title",
    description: "My PDF Description",
    strings: strings,
    presupuesto: presupuesto,
    totalSinIva: totalSinIva,
    cliente: cliente,
    direccion: direccion,
    tipoIva: tipoIva,
  };

  return (
    <PDFViewer width="1000" height="600">
      {/* Pass dynamic props to the PDF component */}
      <MyPDF dynamicData={dynamicProps} />
    </PDFViewer>
  );
};

export default PdfViewer;
